<p-dialog
  [header]="header"
  [(visible)]="visible"
  (onHide)="onVisibleChange(false)"
  [focusOnShow]="false"
  [style]="{ width: dialogSize }"
  [modal]="true"
  appendTo="body"
  [draggable]="false">
  <div>
    <div>{{ description }}</div>
    <ng-content select="[content]"></ng-content>
  </div>
  <ng-template pTemplate="footer">
    <ng-content select="[footer]"></ng-content>
  </ng-template>
</p-dialog>
